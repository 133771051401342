import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IClientIssue } from '@shared-global/interfaces/clients-issues/interfaces/client-issue.interface';
import { IFindClientsIssuesByCriteriaRequest } from '@shared-global/interfaces/clients-issues/interfaces/find-clients-issues-by-criteria-request.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

export type ClientIssueRequests = IFindClientsIssuesByCriteriaRequest &
  QueryParamsInterface;

@Injectable({
  providedIn: 'root',
})
export class ClientIssueService extends AbstractCrudService<
  IClientIssue,
  ClientIssueRequests
> {
  protected override feature = 'clients-issues';
  protected override endPoint = `/clients-issues/`;
}
